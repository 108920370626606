import React from 'react';

function Volunteers() {
  return (
    <div>
      <h1>Volunteers</h1>
      <p>Volunteer management interface will be implemented here.</p>
    </div>
  );
}

export default Volunteers;
