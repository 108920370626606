import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Paper, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useApi } from '../hooks/useApi';

const RegisterSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string(),
  address: Yup.string(),
  idnp: Yup.string(),
});

function Register() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApi();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await apiCall('/api/register', 'POST', values);
      if (response.id) {
        enqueueSnackbar('Registration successful', { variant: 'success' });
        navigate('/login');
      } else {
        enqueueSnackbar(response.error || 'Registration failed', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again.', { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 600 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Register
        </Typography>
        <Formik
          initialValues={{
            username: '',
            password: '',
            name: '',
            email: '',
            phone: '',
            address: '',
            idnp: '',
          }}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="username"
                    label="Username"
                    fullWidth
                    error={touched.username && errors.username}
                    helperText={touched.username && errors.username}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="name"
                    label="Full Name"
                    fullWidth
                    error={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="email"
                    label="Email"
                    fullWidth
                    error={touched.email && errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="phone"
                    label="Phone (optional)"
                    fullWidth
                    error={touched.phone && errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="idnp"
                    label="IDNP (optional)"
                    fullWidth
                    error={touched.idnp && errors.idnp}
                    helperText={touched.idnp && errors.idnp}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="address"
                    label="Address (optional)"
                    fullWidth
                    multiline
                    rows={2}
                    error={touched.address && errors.address}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting}
                sx={{ mt: 3 }}
              >
                {isSubmitting ? 'Registering...' : 'Register'}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
}

export default Register;
