import React from 'react';

function Announcements() {
  return (
    <div>
      <h1>Announcements</h1>
      <p>Announcements interface will be implemented here.</p>
    </div>
  );
}

export default Announcements;
