import React from 'react';

function Vaccinations() {
  return (
    <div>
      <h1>Vaccinations</h1>
      <p>Vaccination management interface will be implemented here.</p>
    </div>
  );
}

export default Vaccinations;
