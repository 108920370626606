import React from 'react';

function Statistics() {
  return (
    <div>
      <h1>Statistics</h1>
      <p>Statistics and analytics will be displayed here.</p>
    </div>
  );
}

export default Statistics;
