import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Pets from './pages/Pets';
import Users from './pages/Users';
import Clinics from './pages/Clinics';
import Vaccinations from './pages/Vaccinations';
import HealthRecords from './pages/HealthRecords';
import Announcements from './pages/Announcements';
import Volunteers from './pages/Volunteers';
import Statistics from './pages/Statistics';
import AuditLogs from './pages/AuditLogs';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <Router>
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                  <Route path="/pets" element={<PrivateRoute><Pets /></PrivateRoute>} />
                  <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
                  <Route path="/clinics" element={<PrivateRoute><Clinics /></PrivateRoute>} />
                  <Route path="/vaccinations" element={<PrivateRoute><Vaccinations /></PrivateRoute>} />
                  <Route path="/health-records" element={<PrivateRoute><HealthRecords /></PrivateRoute>} />
                  <Route path="/announcements" element={<PrivateRoute><Announcements /></PrivateRoute>} />
                  <Route path="/volunteers" element={<PrivateRoute><Volunteers /></PrivateRoute>} />
                  <Route path="/statistics" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                  <Route path="/audit-logs" element={<PrivateRoute><AuditLogs /></PrivateRoute>} />
                </Routes>
              </Layout>
            </Router>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
