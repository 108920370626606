import React from 'react';

function Pets() {
  return (
    <div>
      <h1>Pets</h1>
      <p>Pet management interface will be implemented here.</p>
    </div>
  );
}

export default Pets;
