import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useQuery } from 'react-query';
import { useApi } from '../hooks/useApi';
import { Typography, Paper, Box } from '@mui/material';

function Dashboard() {
  const { currentUser } = useAuth();
  const { apiCall } = useApi();

  const { data: userData, isLoading, error } = useQuery(['user', currentUser?.userId], 
    () => apiCall(`/api/users/${currentUser?.userId}`),
    { enabled: !!currentUser?.userId }
  );

  if (isLoading) return <Typography>Loading user data...</Typography>;
  if (error) return <Typography>Error loading user data: {error.message}</Typography>;

  console.log("User data in Dashboard:", userData); // Add this line for debugging

  const roles = Array.isArray(userData?.roles) ? userData.roles : (userData?.roles ? [userData.roles] : []);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Dashboard</Typography>
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h6" gutterBottom>User Information</Typography>
        <Typography>ID: {userData?.id}</Typography>
        <Typography>Username: {userData?.username}</Typography>
        <Typography>Name: {userData?.name}</Typography>
        <Typography>Email: {userData?.email}</Typography>
        <Typography>Roles: {roles.join(', ')}</Typography>
      </Paper>
    </Box>
  );
}

export default Dashboard;
