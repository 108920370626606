import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useApi } from '../hooks/useApi';
import { useSnackbar } from 'notistack';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Tab,
  Tabs,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Users() {
  const { hasPermission } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    pageSize: 20,
    username: '',
    name: '',
    email: ''
  });
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: usersData, isLoading: usersLoading, error: usersError } = useQuery(['users', searchParams], () =>
    apiCall(`/api/users/search?${new URLSearchParams(searchParams)}`).then(data => {
      console.log('Users data received:', data);
      return data;
    })
  );

  const { data: rolesData, isLoading: rolesLoading, error: rolesError } = useQuery('roles', () =>
    apiCall('/api/roles')
  );

  const users = usersData?.data || [];
  const roles = rolesData?.data || [];

  // Log data and errors
  React.useEffect(() => {
    console.log('Users data:', usersData);
    console.log('Roles data:', rolesData);
    if (usersError) console.error('Error fetching users:', usersError);
    if (rolesError) console.error('Error fetching roles:', rolesError);
  }, [usersData, rolesData, usersError, rolesError]);

  const createUserMutation = useMutation(
    (newUser) => apiCall('/api/users', 'POST', newUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User created successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(`Error creating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const updateUserMutation = useMutation(
    (updatedUser) => apiCall(`/api/users/${updatedUser.id}`, 'PUT', updatedUser),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User updated successfully', { variant: 'success' });
        setUserDialogOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(`Error updating user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const deleteUserMutation = useMutation(
    (userId) => apiCall(`/api/users/${userId}`, 'DELETE'),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Error deleting user: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const createRoleMutation = useMutation(
    (newRole) => apiCall('/api/roles', 'POST', newRole),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        enqueueSnackbar('Role created successfully', { variant: 'success' });
        setRoleDialogOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(`Error creating role: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const updateRoleMutation = useMutation(
    (updatedRole) => apiCall(`/api/roles/${updatedRole.id}`, 'PUT', updatedRole),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        enqueueSnackbar('Role updated successfully', { variant: 'success' });
        setRoleDialogOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(`Error updating role: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const deleteRoleMutation = useMutation(
    (roleId) => apiCall(`/api/roles/${roleId}`, 'DELETE'),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('roles');
        enqueueSnackbar('Role deleted successfully', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(`Error deleting role: ${error.message}`, { variant: 'error' });
      },
    }
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUserDialogOpen = (user = null) => {
    setSelectedUser(user);
    setUserDialogOpen(true);
  };

  const handleRoleDialogOpen = (role = null) => {
    setSelectedRole(role);
    setRoleDialogOpen(true);
  };

  const handlePageChange = (newPage) => {
    setSearchParams(prev => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize) => {
    setSearchParams(prev => ({ ...prev, pageSize: newPageSize, page: 1 }));
  };

  const handleUserSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userData = Object.fromEntries(formData.entries());
    userData.roles = userData.roles.split(',');

    if (selectedUser) {
      updateUserMutation.mutate({ ...selectedUser, ...userData });
    } else {
      createUserMutation.mutate(userData);
    }
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const roleData = Object.fromEntries(formData.entries());

    if (selectedRole) {
      updateRoleMutation.mutate({ ...selectedRole, ...roleData });
    } else {
      createRoleMutation.mutate(roleData);
    }
  };

  const userColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      renderCell: (params) => (
        <Box>
          {params.value.map((role) => (
            <Chip key={role} label={role} size="small" style={{ margin: '0 2px' }} />
          ))}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          {hasPermission('update_user') && (
            <Button
              startIcon={<EditIcon />}
              onClick={() => handleUserDialogOpen(params.row)}
              size="small"
            >
              Edit
            </Button>
          )}
          {hasPermission('delete_user') && (
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => deleteUserMutation.mutate(params.row.id)}
              size="small"
              color="error"
            >
              Delete
            </Button>
          )}
        </Box>
      ),
    },
  ];

  const roleColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box>
          {hasPermission('update_role') && (
            <Button
              startIcon={<EditIcon />}
              onClick={() => handleRoleDialogOpen(params.row)}
              size="small"
            >
              Edit
            </Button>
          )}
          {hasPermission('delete_role') && (
            <Button
              startIcon={<DeleteIcon />}
              onClick={() => deleteRoleMutation.mutate(params.row.id)}
              size="small"
              color="error"
            >
              Delete
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Users" />
          <Tab label="Roles" />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Box>
          {hasPermission('create_user') && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleUserDialogOpen()}
              variant="contained"
              sx={{ mb: 2 }}
            >
              Add User
            </Button>
          )}
          <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={users}
              columns={userColumns}
              page={searchParams.page - 1}
              pageSize={searchParams.pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              rowCount={usersData?.pagination?.total || 0}
              checkboxSelection
              disableSelectionOnClick
              loading={usersLoading}
              getRowId={(row) => row.id}
            />
          </Paper>
        </Box>
      )}
      {tabValue === 1 && (
        <Box>
          {hasPermission('create_role') && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleRoleDialogOpen()}
              variant="contained"
              sx={{ mb: 2 }}
            >
              Add Role
            </Button>
          )}
          <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={roles}
              columns={roleColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              loading={rolesLoading}
            />
          </Paper>
        </Box>
      )}

      {/* User Dialog */}
      <Dialog open={userDialogOpen} onClose={() => setUserDialogOpen(false)}>
        <form onSubmit={handleUserSubmit}>
          <DialogTitle>{selectedUser ? 'Edit User' : 'Add User'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="username"
              label="Username"
              type="text"
              fullWidth
              defaultValue={selectedUser?.username}
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedUser?.name}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              defaultValue={selectedUser?.email}
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="roles-label">Roles</InputLabel>
              <Select
                labelId="roles-label"
                name="roles"
                multiple
                defaultValue={selectedUser?.roles || []}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUserDialogOpen(false)}>Cancel</Button>
            <Button type="submit">{selectedUser ? 'Update' : 'Create'}</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Role Dialog */}
      <Dialog open={roleDialogOpen} onClose={() => setRoleDialogOpen(false)}>
        <form onSubmit={handleRoleSubmit}>
          <DialogTitle>{selectedRole ? 'Edit Role' : 'Add Role'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              defaultValue={selectedRole?.name}
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              defaultValue={selectedRole?.description}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRoleDialogOpen(false)}>Cancel</Button>
            <Button type="submit">{selectedRole ? 'Update' : 'Create'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Users;
