import React from 'react';

function HealthRecords() {
  return (
    <div>
      <h1>Health Records</h1>
      <p>Health records management interface will be implemented here.</p>
    </div>
  );
}

export default HealthRecords;
