import React from 'react';

function Clinics() {
  return (
    <div>
      <h1>Clinics</h1>
      <p>Clinic management interface will be implemented here.</p>
    </div>
  );
}

export default Clinics;
