import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiCall = useCallback(async (endpoint, method = 'GET', body = null, token = null) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const config = {
      method,
      headers,
      credentials: 'include',
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    const response = await fetch(`https://d1-petdb.yoda-md.workers.dev${endpoint}`, config);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'API call failed');
    }
    return response.json();
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setCurrentUser(null);
  }, []);

  const fetchUserData = useCallback(async (token) => {
    try {
      // Decode the JWT to get the user ID
      const payload = JSON.parse(atob(token.split('.')[1]));
      const userId = payload.userId;

      // Fetch user data using the correct endpoint
      const userData = await apiCall(`/api/users/${userId}`, 'GET', null, token);
      setCurrentUser({ ...userData, token });
    } catch (error) {
      console.error('Error fetching user data:', error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [apiCall, logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = async (username, password) => {
    try {
      const data = await apiCall('/auth', 'POST', { username, password });
      if (data.token) {
        localStorage.setItem('token', data.token);
        await fetchUserData(data.token);
        return { token: data.token };
      } else {
        return { error: data.error || 'Login failed' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { error: 'An unexpected error occurred' };
    }
  };

  const hasPermission = (permission) => {
    if (!currentUser || !currentUser.permissions) return false;
    return currentUser.permissions.includes(permission);
  };

  const value = {
    currentUser,
    login,
    logout,
    hasPermission,
    apiCall,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
